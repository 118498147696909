<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  data(){
    return {
      
    }
  },
  watch:{
    $route(to,from){ //監聽路由
      console.log('to:',to)
      console.log(from.name,' -> ',to.name)
      if ( 
        to.name != 'welcomeView' && 
        to.name != 'login' && 
        to.name != 'adminLoginView' && 
        to.name != 'register' &&
        to.name != 'registerInvite' &&
        to.name != 'his-login' &&
        to.name != 'fgpwd'
      ){ //非上述頁面要判斷是否為登入狀態
        console.log('非上述頁面要判斷是否為登入狀態')
        if ( !this.$store.state.userData.user_id ){
          this.$router.push({ path: "/"})  
        }
      }else{
        //回到註冊&登入頁，要清掉資料
        this.$store.commit('userLogOut')
      }
    }
  },
  methods:{},
  mounted(){},
  created(){
    let self = this
    //處理關閉或者重整畫面資料不消失
    let userInfo = localStorage.getItem('userInfo')
    console.log('created userInfo:',userInfo)
    if ( userInfo ){ //資料存在重新寫入
      self.$store.commit('setUserinfo',{
          userData:JSON.parse(userInfo)
      })
    }

    // if ( window.location.pathname == '/his/login' ){ //判斷使用第三方
    //   return
    // }
    
    var url = window.location.href;
    var query = {}
    if(url.indexOf('?')!=-1){
        //之後去分割字串把分割後的字串放進陣列中
        var ary1 = url.split('?');
        var ary2 = ary1[1].split('&');
        for (let index = 0; index < ary2.length; index++) {
          const item = ary2[index];
          var ary3 = item.split('=');
          query[ary3[0]] = ary3[1]
        }
        console.log('query:',query)
    }
    if (query.data && query.data != 'undefined' ){ //有data才丟進來
      console.log('inviteCode->',query.data)
      query.data = decodeURIComponent(query.data)
    }
    
    // self.$router.push({ path: "/",query:query})

    // self.$router.push({ path: "/"})   
  }
}
</script>
<style lang="css" src="../public/css/dcp-base.css" scoped></style>
